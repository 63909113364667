// Core components to be included with every app

// Actions
import OActionSave from 'o365.vue.components.Action.Save.vue';
import OActionDelete from 'o365.vue.components.Action.Delete.vue';
import OActionCancel from 'o365.vue.components.Action.Cancel.vue';

// Layout
// import OContainer from 'o365.vue.components.Container.vue';
import OColContainer from 'o365.vue.components.ColContainer.vue';
import ORowContainer from 'o365.vue.components.RowContainer.vue';
import OSizerPanel from 'o365.vue.components.SizerPanel.vue';

// import OHeigthSizer from 'o365.vue.components.HeigthSizer.vue';
// import OWidthSizer from 'o365.vue.components.WidthSizer.vue';

import ONavbar from 'o365.vue.components.ONavbar.vue';
import OTabs from 'o365.vue.components.Tabs.vue';
import OTab from 'o365.vue.components.Tab.vue';

//import OToolbar from 'o365.vue.components.Toolbar.vue';

import OForm from 'o365.vue.components.Form.vue';
import OFormControl from 'o365.vue.components.FormControl.vue';


import ODropdown from 'o365.vue.components.DropDown.vue';
import OModal from 'o365.vue.components.Modal.vue';

// Data display
import ODataGrid from 'o365.vue.components.DataGrid.vue';
//import ODataTable from 'o365.vue.components.DataTable.vue';

import { OCheck, OLink } from 'o365.vue.components.renderers.jsx';
import { OColumn, OColumnGroup } from 'o365.vue.components.DataGrid.Column.jsx';

// Inputs
import ODataLookup from 'o365.vue.components.DataLookup.vue';
import ODatePicker from 'o365.vue.components.DatePicker.vue';
import OContentEditable from 'o365.vue.components.ContentEditable.vue';
import OTextArea from  'o365.vue.components.TextArea.vue';

import OToolbarAction from 'o365.vue.components.ToolbarAction.vue';

import { OTextEditor, ONumberEditor, OBitEditor, ODateEditor } from 'o365.vue.components.inputEditors.jsx';

// Filtering
import OFilterString from 'o365.vue.components.FilterString.vue';

export default {
    install: (app, options) => {
        // Component names must be in PascalCase for them to resolve both in pascal and kebab cases.

        // Actions
        app.component('OActionSave', OActionSave);
        app.component('OActionDelete', OActionDelete);
        app.component('OActionCancel', OActionCancel);

        // Layout
        // app.component('OContainer', OContainer);
        app.component('OColContainer', OColContainer);
        app.component('ORowContainer', ORowContainer);
        app.component('OSizerPanel', OSizerPanel);

        // app.component('OHeigthSizer', OHeigthSizer);
        // app.component('OWidthSizer', OWidthSizer);

        app.component('ONavbar', ONavbar);
        

        app.component('OTabs', OTabs);
        app.component('OTab', OTab);

        app.component('OForm', OForm);
        app.component('OFormControl', OFormControl);

        app.component('ODropdown', ODropdown);
        app.component('OModal', OModal);

        // Data display
        app.component('ODataGrid', ODataGrid);
        //app.component('ODataTable', ODataTable);

        app.component('OCheck', OCheck);
        app.component('OLink', OLink);

        app.component('OColumn', OColumn);
        app.component('OColumnGroup', OColumnGroup);

        // Inputs
        app.component('ODataLookup', ODataLookup);
        app.component('ODatePicker', ODatePicker);
        
        app.component('OToolbarAction', OToolbarAction);

        app.component('OTextEditor', OTextEditor);
        app.component('ONumberEditor', ONumberEditor);
        app.component('OBitEditor', OBitEditor);
        app.component('ODateEditor', ODateEditor);
        app.component('OContentEditable', OContentEditable);
        app.component('OTextArea', OTextArea);

        // Filtering
        app.component('OFilterString', OFilterString);

    }
}
